import React from "react";
import Featured from "../../sections/Featured/Featured";
import { Landing } from "../../sections/Landing";
import { Offers } from "../../sections/Offers";
import Products from "../../sections/Products/Products";
import Recent from "../../sections/Recent/Recent";
import { Services } from "../../sections/Services";
import Specials from "../../sections/Specials/Specials";

function Home() {
  return (
    <div>
      <Landing />
      <Products />
      <Services />
      <Featured />
      <Offers />
      <Specials />
      <Recent />
    </div>
  );
}

export default Home;
