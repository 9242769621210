import React from "react";
import styles from "./NoSearchResult.module.css";

function NoSearchResult() {
  return (
    <div className={styles.container}>
      <div className={styles.emoji}>( ˘︹˘ )</div>
      <div className={styles.message}>Sorry, we couldn't find any results.</div>
    </div>
  );
}

export default NoSearchResult;
