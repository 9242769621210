import { baseRequest } from "./base";

const loginFacebook = async (data) => {
  let loginData = {
    token: data.token,
  };

  var response = await baseRequest({
    url: "/api/login-facebook",
    method: "POST",
    body: loginData,
  });

  return response;
};

const loginGoogle = async (data) => {
  let loginData = {
    token: data.token,
  };

  var response = await baseRequest({
    url: "/api/login-google",
    method: "POST",
    body: loginData,
  });

  return response;
};
const logout = async () => {
  var response = await baseRequest({ url: "/api/logout", method: "GET" });
  return response;
};

const isLogged = async (signal) => {
  var response = await baseRequest({ url: "/api/user", method: "GET", signal: signal });
  return response;
};

export { logout, isLogged, loginFacebook, loginGoogle };
