import cx from "classnames";
import React from "react";
import styles from "./Policies.module.css";

function Delivery() {
  return (
    <div className={cx(styles.container, "container")}>
      <h2 className={styles.title}>Delivery Policy</h2>
      <p className={styles.paragraph}>
        If you opt for delivery you have to provide details like name, address, phone number and the
        desired time frame of the package handoff. You will receive your delivery a day after we
        ship your package, at the exact time-frame you select. (The time frames can only be chosen
        from our list and not manually entered). They are 09:00-12:00, 12:00-14:00, 14:00-16:00,
        16:00-18:00, 18:00-20:00, 19:00-21:00 and 20:00-21:00.
      </p>
      <p className={styles.paragraph}>
        We are working together with JP post and Sagawa to provide the best service possible.
        Packages that require refrigeration will only be handled by Sagawa.
      </p>

      <p className={styles.paragraph}>
        For wholesale customers, we can deliver your order with our own company vehicles in case
        there has been an agreement between the representatives from each company. The time-frame
        and delivery date will also be specified during the previously mentioned ‘agreement’.
      </p>
    </div>
  );
}

export default Delivery;
