import React from "react";
import styles from "./Policies.module.css";
import cx from "classnames";
import { Link } from "react-router-dom";

function Terms() {
  return (
    <div className={cx(styles.container, "container")}>
      <h2 className={styles.title}>Terms and Conditions</h2>
      <p className={styles.paragraph}>
        Please read the Terms and Conditions carefully before using BarahiFoods.jp.
      </p>
      <p className={styles.paragraph}>
        This electronic record is generated by a computer system and does not require any physical
        or digital signatures.
      </p>
      <h4 className={styles.sectionTitle}>INTRODUCTION</h4>
      <p className={styles.paragraph}>
        Welcome to BarahiFoods.jp also hereby known as “we", "us" or "D.Riku”. We are an online
        marketplace and these are the terms and conditions governing your access and use of Barahi
        Foods (the "Site"). By using the Site, you hereby accept these terms and conditions
        (including the linked information herein) and represent that you agree to comply with these
        terms and conditions (the "User Agreement"). This User Agreement is deemed effective upon
        your use of the Site which signifies your acceptance of these terms. If you do not agree to
        be bound by this User Agreement please do not access, register with or use this Site. This
        site is owned and operated by Barahi Foods.
      </p>
      <p className={styles.paragraph}>
        The Site reserves the right to change, modify, add, or remove portions of these Terms and
        Conditions at any time without any prior notification. Changes will be effective when posted
        on the Site with no other notice provided. Please check these Terms and Conditions regularly
        for updates. Your continued use of the Site following the posting of changes to Terms and
        Conditions of use constitutes your acceptance of those changes.
      </p>
      <h4 className={styles.sectionTitle}>YOUR ACCOUNT</h4>
      <p className={styles.paragraph}>
        To access certain services offered by the platform, we may require that you create an
        account with us or provide personal information to complete the creation of an account.You
        are responsible for maintaining the confidentiality of your user identification, password,
        account details and related private information. You agree to accept this responsibility and
        ensure your account and its related details are maintained securely at all times and all
        necessary steps are taken to prevent misuse of your account. You should inform us
        immediately if you have any reason to believe that your password has become known to anyone
        else, or if the password is being, or is likely to be, used in an unauthorized manner.
      </p>
      <h4 className={styles.sectionTitle}>PRIVACY</h4>
      <p className={styles.paragraph}>
        Please review our{" "}
        <Link to="/policies/privacy/" className={styles.link}>
          Privacy Agreement
        </Link>
        , which also governs your visit to the Site.
      </p>
    </div>
  );
}

export default Terms;
