import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CircularProgress, IconButton } from "@mui/material";
import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProductById, getRelatedProducts } from "../../services/api/products";
import * as cartActions from "../../store/cart/actionTypes";
import { StoreContext } from "../../store/store";
import { CustomBreadcrumb } from "../Breadcrumb";
import Product from "../Product/Product";
import ProductSkeleton from "../Product/ProductSkeleton";
import styles from "./ProductDetails.module.css";

function ProductDetails() {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [itemCartState, setItemCartState] = useState(null);
  const [state, dispatch] = useContext(StoreContext);
  const { userState, cartState } = state;
  const [loading, setLoading] = useState(false);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);

  const handleIncrement = (item) => {
    if (Object.keys(item).length === 0) return;

    const subtotal = cartState.subtotal + item.price1;
    dispatch({
      type: cartActions.UPDATE_ITEM_ON_BASKET,
      update: 1,
      id: item.id,
      subtotal: subtotal,
    });
  };

  const handleDecrement = (item) => {
    if (Object.keys(item).length === 0) return;

    const subtotal = cartState.subtotal - item.price1;
    dispatch({
      type: cartActions.UPDATE_ITEM_ON_BASKET,
      update: -1,
      id: item.id,
      subtotal: subtotal,
    });
  };

  const productExists = (item) => {
    if (Object.keys(item).length === 0) return false;

    const isInCart = cartState.items.filter((itm) => itm.id === item.id);
    if (isInCart.length) return true;
    return false;
  };

  const addItem = (item) => {
    if (Object.keys(item).length === 0) return;

    const subtotal = cartState.subtotal + item.price1;
    if (!productExists(item)) {
      item = {
        ...item,
        purchasedUnits: 1,
      };
      dispatch({ type: cartActions.ADD_NEW_ITEM_TO_BASKET, item: item, subtotal: subtotal });
    } else {
      dispatch({
        type: cartActions.UPDATE_ITEM_ON_BASKET,
        update: 1,
        id: item.id,
        subtotal: subtotal,
      });
    }
  };

  const removeItem = (item) => {
    if (Object.keys(item).length === 0) return;

    const updatedUnits = cartState.items.filter((itm) => itm.id === item.id)[0].purchasedUnits;
    const subtotal = cartState.subtotal - item.price1 * updatedUnits;
    dispatch({
      type: cartActions.REMOVE_ITEM,
      id: item.id,
      subtotal: subtotal,
    });
  };

  useEffect(() => {
    let abort = new AbortController();
    setLoading(true);
    getProductById(id, abort.signal)
      .then((res) => {
        setProduct(res.data);
        setLoading(false);
        let breadcrumbItems = [
          { title: "Home", to: "/" },
          { title: "Products", to: "/all-products/" },
          {
            title: res.data.product_categories.title,
            to: `/all-products?category_id=${res.data.product_categories.id}&title=${res.data.product_categories.title}`,
          },
          { title: res.data.title, to: null },
        ];
        setBreadcrumb(breadcrumbItems);
      })
      .catch((err) => {
        setLoading(false);
      });

    return () => {
      abort.abort();
    };
  }, [id]);

  useEffect(() => {
    let abort = new AbortController();
    setLoading(true);
    getRelatedProducts(id, abort.signal)
      .then((res) => {
        console.log(res.data.recommended_products);
        setRelatedProducts(res.data.recommended_products.slice(0, 5));
      })
      .catch((err) => {})
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 1000)
      );

    return () => {
      abort.abort();
    };
  }, [id]);

  useEffect(() => {
    const isInCart = cartState.items.find((itm) => itm.id === product.id);
    setItemCartState(isInCart);
  }, [cartState, product]);

  return loading ? (
    <div
      style={{ height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div className={cx("container", styles.container)}>
      <CustomBreadcrumb items={breadcrumb} />
      <div className={styles.grid}>
        <figure className={styles.imageContainer}>
          <img
            alt="name"
            width="100%"
            height="100%"
            src={process.env.REACT_APP_SERVER_URL + product?.image}
            style={{ objectFit: "contain" }}
          />
        </figure>
        <div className={styles.content}>
          <h1 className={styles.itemTitle}>{product?.title}</h1>
          <p className={styles.itemSubtitle}>{product?.description}</p>
          <p className={styles.itemNote}>
            {" "}
            *Note: Every product available in Barahi Stores are stored in favourable environments.
          </p>
          <div className={styles.typeContainer}>
            <div className={styles.itemCategory}>
              Categories: {product?.product_categories?.title}
            </div>
            <div className={styles.itemAvailability}>
              Availability:{" "}
              <span style={product?.status === 1 ? { color: "#00543c" } : { color: "#ff000d" }}>
                {product?.status === 1 ? "In Stock" : "Out of Stock"}
              </span>
            </div>
          </div>
          <p className={styles.itemPrice}>¥ {product?.price1}</p>
          {itemCartState ? (
            <div className={styles.cartBtn}>
              <IconButton
                className={cx(styles.iconBtn, styles.removeIcon)}
                onClick={() => {
                  if (itemCartState.purchasedUnits > 1) {
                    handleDecrement(product);
                  } else {
                    removeItem(product);
                  }
                }}
              >
                <RemoveIcon />
              </IconButton>
              <p className={styles.cartNumber}>x{itemCartState.purchasedUnits}</p>
              <IconButton
                className={cx(styles.iconBtn, styles.addIcon)}
                onClick={() => {
                  handleIncrement(product);
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          ) : (
            <button
              className={cx("btn", "btnPrimary", styles.btn)}
              onClick={() => {
                addItem(product);
              }}
              disabled={product.status !== 1}
            >
              Add to Cart
            </button>
          )}
        </div>
      </div>
      <div className={styles.headerContainer}>
        <h2 className={cx("h2", "section-title", styles.sectionTitle)}>Related Products</h2>
      </div>

      {loading ? (
        <div className={cx(styles.topProductList, "grid-list")}>
          <ProductSkeleton />
          <ProductSkeleton />
          <ProductSkeleton />
          <ProductSkeleton />
          <ProductSkeleton />
        </div>
      ) : (
        <div className={cx(styles.topProductList, "grid-list")}>
          {relatedProducts.map((item, index) => (
            <Product item={item} key={item.id} />
          ))}
        </div>
      )}
    </div>
  );
}

export default ProductDetails;
