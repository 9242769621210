import cx from "classnames";
import React, { useEffect, useState } from "react";
import Product from "../../components/Product/Product";
import ProductSkeleton from "../../components/Product/ProductSkeleton";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getRecentProducts } from "../../services/api/products";
import styles from "./Recent.module.css";

function Recent() {
  const { width } = useWindowDimensions();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let abort = new AbortController();
    setLoading(true);
    getRecentProducts(abort.signal)
      .then((res) => {
        setProducts(res.data.products);
        setLoading(false);
      })
      .catch((err) => {})
      .finally(() =>
        setTimeout(() => {
          setLoading(false);
        }, 1000)
      );

    return () => {
      abort.abort();
    };
  }, []);
  return (
    <section className={cx("section", styles.topProduct)}>
      <div className="container">
        <div className={styles.headerContainer}>
          <h2 className={cx("h2", "section-title", styles.sectionTitle)}>Recently Added</h2>
        </div>
        {loading ? (
          <div className={cx(styles.topProductList, "grid-list")}>
            <ProductSkeleton />
            <ProductSkeleton />
            <ProductSkeleton />
            <ProductSkeleton />
            <ProductSkeleton />
          </div>
        ) : (
          <div className={cx(styles.topProductList, "grid-list")}>
            {width < 480
              ? products.slice(0, 2).map((item, index) => <Product item={item} key={item.id} />)
              : width < 768
              ? products.slice(0, 4).map((item, index) => <Product item={item} key={item.id} />)
              : width < 992
              ? products.slice(0, 6).map((item, index) => <Product item={item} key={item.id} />)
              : products.map((item, index) => <Product item={item} key={item.id} />)}
          </div>
        )}
      </div>
    </section>
  );
}

export default Recent;
