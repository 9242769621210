import { baseRequest } from "./base";

const checkoutDisplay = async (orderId, signal) => {
  var response = await baseRequest({
    url: `/api/checkout-display?order_id=${orderId}`,
    method: "GET",
    signal: signal,
  });
  return response;
};

const stripeCheckout = async (data) => {
  var response = await baseRequest({
    url: `/api/stripe-checkout`,
    method: "POST",
    body: data,
  });
  return response;
};

const codCheckout = async (data) => {
  var response = await baseRequest({
    url: `/api/cod-checkout`,
    method: "POST",
    body: data,
  });
  return response;
};

export { checkoutDisplay, stripeCheckout, codCheckout };
