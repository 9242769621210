import React from "react";
import styles from "./Policies.module.css";
import cx from "classnames";

function Refund() {
  return (
    <div className={cx(styles.container, "container")}>
      <h2 className={styles.title}>Refund Policy</h2>

      <h4 className={styles.sectionTitle}>IN STORE</h4>
      <p className={styles.paragraph}>
        You ask for a refund within two days (48hrs) of purchase in case you are not satisfied with
        our product. You need to show the purchase receipt in order to get a refund, so please don't
        forget to bring it. The item packaging should be intact.
      </p>
      <h4 className={styles.sectionTitle}>DURING DELIVERY</h4>
      <p className={styles.paragraph}>
        You can request the refund before the order has been shipped from the store.Buyer should
        have a reason for canceling the order as order confirmation is taken by a phone call. There
        is no refund possible once an order has been shipped. For Credit Card payments a 3.6%
        processing fee will be deducted which is captured by our payment gateway partner. We always
        have the right to refuse a refund after investigating. In case of damaged products, a refund
        request can be applied even after the delivery but it should be completed within two days
        (48hrs) after receiving the package.
      </p>
    </div>
  );
}

export default Refund;
