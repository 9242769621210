import React, { useContext } from "react";
import styles from "./Login.module.css";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.min.css";

//custom imports
import { CssBaseline, Dialog } from "@mui/material";
import { Link } from "react-router-dom";
import { StoreContext } from "../../store/store";
import * as userActions from "./../../store/user/actionTypes";
import LoginForm from "./LoginForm";

const Login = ({ open, setOpen, onLoginSuccess }) => {
  const [state, dispatch] = useContext(StoreContext);

  // let { handleLoginClick, handleRegisterClick, forwardTo } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const successLogin = (loginData) => {
    dispatch({
      type: userActions.LOGGED_IN,
      payload: {
        ...loginData,
        is_wholesale: false,
        // loginData.is_wholesale?.toString() === "1" ? true : false,
      },
    });

    if (onLoginSuccess) {
      onLoginSuccess();
    }
    handleClose();

    // forwardTo && history.replace({ pathname: forwardTo });
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <CssBaseline />
      <div className={styles.modal}>
        <h1 className="h3">Log in</h1>
        <p className={styles.subtitle}>
          By continuing, you agree to our <Link to="/terms&conditions/">Terms & Conditions</Link>{" "}
          and <Link to="/privacy-policy/">Privacy Policy</Link>.
        </p>

        <LoginForm
          //   notifyError={notifyError}
          //   notifySuccess={notifySuccess}
          //   setShowLoader={setShowLoader}
          successLogin={successLogin}
        />
      </div>
    </Dialog>
  );
};

export default Login;
