import React from "react";
import styles from "./Services.module.css";
import cx from "classnames";

const data = [
  { image: "/images/service-icon-1.png", title: "Delivery All Over Japan" },
  { image: "/images/service-icon-2.png", title: "Safe Payment" },
  { image: "/images/service-icon-3.png", title: "Best Prices " },
];

function Services() {
  return (
    <section className={cx("section", styles.service)}>
      <div className="container">
        <ul className={styles.serviceList}>
          {data.map((item, index) => (
            <li className={styles.serviceItem} key={index}>
              <div className={styles.itemIcon}>
                <img src={item.image} width="40" height="40" loading="lazy" alt="service" />
              </div>
              <h3 className={cx("h3", styles.itemTitle)}>{item.title}</h3>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default Services;
