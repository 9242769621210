import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { loadStripe } from "@stripe/stripe-js";
import cx from "classnames";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { syncCart } from "../../services/api/cart";
import { stripeCheckout } from "../../services/api/checkout";
import * as cartActions from "../../store/cart/actionTypes";
import { StoreContext } from "../../store/store";
import { getCookie } from "../../utils/cookie/cookies";
import styles from "./Checkout.module.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}

const Checkout = () => {
  let token = getCookie("token") || "";
  const [state, dispatch] = useContext(StoreContext);
  const { cartState } = state;
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [billingData, setBillingData] = useState({
    first_name: "",
    last_name: "",
    street_address: "",
    house_number: "",
    landmark: "",
    city: "",
    delivery_date: null,
    paymentOption: null,
  });

  const [paymentData, setPaymentData] = useState({
    email: "",
    phone_number: "",
    payment_option: "",
  });

  const handleChange = (e) => {
    setPaymentData({
      ...paymentData,
      payment_option: e.target.name,
    });
  };

  const handleFormSubmit = (e) => {
    let err = {};
    if (billingData.first_name.length === 0) {
      err["first_name"] = ["First name is required"];
    }
    if (billingData.last_name.length === 0) {
      err["last_name"] = ["Last name is required"];
    }
    if (billingData.street_address.length === 0) {
      err["street_address"] = ["Street Address name is required"];
    }
    if (billingData.house_number.length === 0) {
      err["house_number"] = ["House number is required"];
    }
    if (billingData.city.length === 0) {
      err["city"] = ["City is required"];
    }
    if (paymentData.email.length === 0) {
      err["email"] = ["Email is required"];
    } else if (!isValidEmail(paymentData.email)) {
      err["email"] = ["Invalid Email"];
    }
    if (paymentData.phone_number.length === 0) {
      err["phone_number"] = ["Phone number is required"];
    }
    if (paymentData.payment_option.length === 0) {
      err["payment_option"] = ["Select a payment option"];
    }

    setErrors(err);
    if (Object.keys(err).length > 0) {
      return;
    }
    if (paymentData.payment_option === "card-payment") {
      makePayment(billingData, paymentData);
    } else if (paymentData.payment_option === "cod") {
      const requestBody = {
        user: {
          data: {
            name: [billingData.first_name, billingData.last_name].join(" "),
            phone: paymentData.phone_number,
            default_address: {
              house: billingData.house_number,
              street_address: billingData.street_address,
              landmark: billingData.landmark,
            },
          },
        },
        orders: cartState.items.map(({ id, purchasedUnits }) => ({
          id: id,
          quantity: purchasedUnits,
        })),
      };
      navigate("/checkout/cod/", {
        state: {
          requestBody: requestBody,
          billingData: billingData,
          paymentData: paymentData,
        },
      });
    }
  };

  async function makePayment(billingData, paymentData) {
    setLoading(true);
    const stripe = await stripePromise;
    const requestBody = {
      user: {
        data: {
          name: [billingData.first_name, billingData.last_name].join(" "),
          phone: paymentData.phone_number,
          default_address: {
            house: billingData.house_number,
            street_address: billingData.street_address,
            landmark: billingData.landmark,
          },
        },
      },
      orders: cartState.items.map(({ id, purchasedUnits }) => ({
        id: id,
        quantity: purchasedUnits,
      })),
    };

    const session = await stripeCheckout(requestBody);
    await stripe.redirectToCheckout({
      sessionId: session.data.stripe_session,
    });
  }

  useEffect(() => {
    if (cartState.quantity > 0) {
      syncCart(cartState)
        .then((res) => {
          dispatch({
            type: cartActions.SYNC_CART,
            items: res.data.items,
            subtotal: res.data.subtotal,
          });
        })
        .catch((err) => {});
    }
  }, []);

  return (
    <Box width="80%" m="60px auto" className={cx(styles.container, "container")}>
      <Box>
        <Box m="30px auto">
          {/* BILLING FORM */}
          <Box>
            <Typography sx={{ mb: "15px" }} fontSize="18px">
              Billing Information
            </Typography>
            <form noValidate autoComplete="off">
              <Box
                display="grid"
                gap="15px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  type="text"
                  label="First Name"
                  value={billingData.first_name}
                  onChange={(e) => setBillingData({ ...billingData, first_name: e.target.value })}
                  error={errors["first_name"]}
                  helperText={errors["first_name"]}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  type="text"
                  label="Last Name"
                  value={billingData.last_name}
                  onChange={(e) => setBillingData({ ...billingData, last_name: e.target.value })}
                  error={errors["last_name"]}
                  helperText={errors["last_name"]}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  type="text"
                  label="Street Address"
                  value={billingData.street_address}
                  onChange={(e) =>
                    setBillingData({ ...billingData, street_address: e.target.value })
                  }
                  error={errors["street_address"]}
                  helperText={errors["street_address"]}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  type="text"
                  label="House Number"
                  value={billingData.house_number}
                  onChange={(e) => setBillingData({ ...billingData, house_number: e.target.value })}
                  error={errors["house_number"]}
                  helperText={errors["house_number"]}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  type="text"
                  label="Landmark (optional)"
                  value={billingData.landmark}
                  onChange={(e) => setBillingData({ ...billingData, landmark: e.target.value })}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  type="text"
                  label="City"
                  value={billingData.city}
                  onChange={(e) => setBillingData({ ...billingData, city: e.target.value })}
                  error={errors["city"]}
                  helperText={errors["city"]}
                  sx={{ gridColumn: "span 2" }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Preferred Date for Delivery (Optional)"
                    onChange={(value) => setBillingData({ ...billingData, delivery_date: value })}
                    value={billingData.delivery_date}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        sx={{ gridColumn: "span 2" }}
                        error={errors["date"]}
                        helperText={errors["date"]}
                      />
                    )}
                  />
                </LocalizationProvider>
                <TextField
                  fullWidth
                  type="email"
                  label="Email"
                  value={paymentData.email}
                  onChange={(e) => setPaymentData({ ...paymentData, email: e.target.value })}
                  error={errors["email"]}
                  helperText={errors["email"]}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  type="text"
                  label="Phone number"
                  value={paymentData.phone_number}
                  onChange={(e) => setPaymentData({ ...paymentData, phone_number: e.target.value })}
                  error={errors["phone_number"]}
                  helperText={errors["phone_number"]}
                  sx={{ gridColumn: "span 2" }}
                />
                <FormControl error={errors["payment_option"]}>
                  <RadioGroup
                    aria-labelledby="payment-option"
                    defaultValue="female"
                    name="payment-option"
                    onChange={(e) => {
                      setPaymentData({ ...paymentData, payment_option: e.target.value });
                    }}
                  >
                    <FormControlLabel value="cod" control={<Radio />} label="Cash on Delivery" />
                    <FormControlLabel
                      value="card-payment"
                      control={<Radio />}
                      label="Card Payment"
                    />
                  </RadioGroup>
                  <FormHelperText>{errors["payment_option"]}</FormHelperText>
                </FormControl>
              </Box>
            </form>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" gap="20px" m="30px auto">
          <Button
            style={{ position: "relative" }}
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            disabled={loading}
            onClick={() => {
              handleFormSubmit();
            }}
            sx={{
              backgroundColor: "#991e2d",
              boxShadow: "none",
              color: "white",
              borderRadius: 0,
              padding: "15px 40px",
              "&:hover": {
                backgroundColor: "#b42233",
              },
            }}
          >
            Place Order
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "#52C41A",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Checkout;
