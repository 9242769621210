import axiosInstance from "./axiosInstance";

const baseRequest = async ({ url, method = "GET", body = null, options, signal, contentType }) => {
  let response = {};
  if (method === "POST") {
    response = await axiosInstance({ options: options, contentType }).post(url, body);
  } else if (method === "PUT") {
    response = await axiosInstance({ options: options }).put(url, body);
  } else if (method === "DELETE") {
    response = await axiosInstance({ options: options }).delete(url);
  } else if (method === "GET") {
    response = await axiosInstance({ options: options }).get(url, { signal: signal });
  }

  return response.data;
};

export { baseRequest };
