import { Typography } from "@mui/material";
import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDeliveryCharge } from "../../services/api/products";
import * as cartActions from "../../store/cart/actionTypes";
import { StoreContext } from "../../store/store";
import Login from "../Login/Login";
import styles from "./Cart.module.css";
import CartItem from "./CartItem";

const Cart = ({ cartActive, setCartActive }) => {
  const navigate = useNavigate();
  const [state, dispatch] = useContext(StoreContext);
  const { userState, cartState } = state;
  const [cartTotal, setCartTotal] = useState({ subtotal: 0, deliveryCharge: 0, chargeStatus: {} });
  const resetCart = () => {
    dispatch({ type: cartActions.RESET_BASKET });
  };
  const [showLoginDialog, setShowLoginDialog] = useState(false);

  useEffect(() => {
    let abort = new AbortController();
    getDeliveryCharge(cartState.items, abort.signal)
      .then((res) => {
        setCartTotal({
          deliveryCharge: res.data.delivery_charge,
          subtotal: res.data.products_charge,
          chargeStatus: res.data.charge_status,
        });
      })
      .catch((err) => {});

    return () => {
      abort.abort();
    };
  }, [cartState]);

  const removeItem = (item) => {
    const updatedUnits = cartState.items.filter((itm) => itm.id === item.id)[0].purchasedUnits;
    const subtotal = cartState.subtotal - item.price1 * updatedUnits;
    dispatch({
      type: cartActions.REMOVE_ITEM,
      id: item.id,
      subtotal: subtotal,
    });
  };

  const handleIncrement = (item) => {
    const subtotal = cartState.subtotal + item.price1;
    dispatch({
      type: cartActions.UPDATE_ITEM_ON_BASKET,
      update: 1,
      id: item.id,
      subtotal: subtotal,
    });
  };

  const handleDecrement = (item) => {
    const subtotal = cartState.subtotal - item.price1;

    dispatch({
      type: cartActions.UPDATE_ITEM_ON_BASKET,
      update: -1,
      id: item.id,
      subtotal: subtotal,
    });
  };

  const onLoginSuccess = () => {
    setCartActive(false);
    navigate("/checkout/");
  };

  return (
    <div className={cx(styles.sidePanel, cartActive && styles.active)}>
      {showLoginDialog && (
        <Login
          open={showLoginDialog}
          setOpen={setShowLoginDialog}
          onLoginSuccess={onLoginSuccess}
        />
      )}

      <div className={styles.header}>
        <div className={styles.mycart}>
          <div className={styles.cartIcon}>
            <ion-icon name="cart-outline"></ion-icon>
          </div>
          My Cart
        </div>
        <button onClick={resetCart} className={styles.clearCart}>
          Clear Cart
        </button>
        <button
          className={styles.panelCloseBtn}
          aria-label="Close cart"
          onClick={() => setCartActive(false)}
        >
          <ion-icon name="close-outline"></ion-icon>
        </button>
      </div>

      {cartState.quantity > 0 ? (
        <>
          <div className={styles.details}>
            You can add{" "}
            {Object.keys(cartTotal.chargeStatus).map((item, index) => {
              return (
                <div className={styles.weight}>
                  {cartTotal.chargeStatus[item].breakpoint} kg of {item}
                  {index < Object.keys(cartTotal.chargeStatus).length - 1 ? " or " : " "}
                </div>
              );
            })}
            under the same shipping charge.
          </div>

          <ul className={styles.panelList}>
            {cartState.items.map((item, index) => (
              <li className={styles.panelItem} key={item.id}>
                <CartItem
                  item={item}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  removeItem={removeItem}
                />
              </li>
            ))}
          </ul>
          <div className={styles.subtotalContainer}>
            <div className={styles.subtotal}>
              <p className={styles.subtotalText}>Subtotal :</p>
              <data className={styles.subtotalValue} value="¥215.14">
                ¥ {cartState.subtotal}
              </data>
            </div>
            <div className={styles.subtotal}>
              <p className={styles.subtotalText}>Delivery :</p>
              <data className={styles.subtotalValue} value="¥215.14">
                ¥ {cartTotal.deliveryCharge}
              </data>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.subtotal}>
              <p className={styles.subtotalText}>Total :</p>
              <data className={styles.subtotalValue} value="¥215.14">
                ¥ {cartTotal.deliveryCharge + cartTotal.subtotal}
              </data>
            </div>
            <div
              className={styles.panelBtn}
              onClick={() => {
                if (userState.isLoggedIn) {
                  setCartActive(false);
                  navigate("/checkout/");
                } else {
                  setCartActive(false);
                  setShowLoginDialog(true);
                }
              }}
            >
              Checkout
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.emptycart}>
            <Typography variant="h5" style={{ textAlign: "center" }}>
              Your cart is empty
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
