import React from "react";
import styles from "./Footer.module.css";
import cx from "classnames";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerTop}>
        <div className={cx(styles.container, "container")}>
          <div className={styles.footerBrand}>
            <div className={styles.logo}>Barahi Foods</div>
            <ul className={styles.socialList}>
              <li>
                <a href="#" className={styles.socialLink}>
                  <ion-icon name="logo-facebook"></ion-icon>
                </a>
              </li>
              <li>
                <a href="#" className={styles.socialLink}>
                  <ion-icon name="logo-instagram"></ion-icon>
                </a>
              </li>
            </ul>

            <ul className={styles.footerList}>
              <li>
                <a className={styles.footerDetail}>
                  <div className={styles.icon}>
                    <ion-icon name="location"></ion-icon>
                  </div>
                  Tokyo, Shinjuku, Hyakunincho 2-10-9
                </a>
              </li>
              <li>
                <a className={styles.footerDetail}>
                  <div className={styles.icon}>
                    <ion-icon name="call"></ion-icon>
                  </div>
                  03-3363-1145, 090-6094-9954
                </a>
              </li>
              <li>
                <a className={styles.footerDetail}>
                  <div className={styles.icon}>
                    <ion-icon name="mail"></ion-icon>
                  </div>
                  barahistore@gmail.com
                </a>
              </li>
            </ul>
          </div>
          <ul className={styles.footerList}>
            <li>
              <p className={styles.footerListTitle}>Company</p>
            </li>
            <li>
              <Link to="/policies/privacy/" className={styles.footerLink}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/policies/delivery/" className={styles.footerLink}>
                Delivery Policy
              </Link>
            </li>
            <li>
              <Link to="/policies/refund/" className={styles.footerLink}>
                Refund Policy
              </Link>
            </li>
            <li>
              <Link to="/policies/terms/" className={styles.footerLink}>
                Terms & Conditions
              </Link>
            </li>
          </ul>
          <ul className={styles.footerList}>
            <li>
              <p className={styles.footerListTitle}>Find Us Here</p>
            </li>
            <div className={styles.location}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15068.1213018!2d139.6977015917559!3d35.70383639126448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d2f16f7e715%3A0x3901db0f57688eed!2zSmFwYW4sIOOAkjE2OS0wMDczIFRva3lvLCBTaGluanVrdSBDaXR5LCBIeWFrdW5pbmNoxY0sIDItY2jFjW1l4oiSMTDiiJI5IOaWsOWkp-S5heS_neOCpOODi-OCt-ODo-ODq-ODj-OCpuOCuQ!5e0!3m2!1sen!2snp!4v1679306189954!5m2!1sen!2snp"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </ul>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className={cx(styles.container, "container")}>
          <p className={styles.copyright}>&copy;2023 Barahi Foods. All Rights Reserved.</p>
          <p className={styles.poweredBy}>
            Powered By:
            <span>
              <a href="https://twoacesolutions.com/" target="_blank">
                Two Ace Solutions Pvt. Ltd.
              </a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
