import axios from "axios";
import { getCookie } from "../../utils/cookie/cookies";

const axiosInstance = ({ options, contentType }) => {
  let token = getCookie("token") || "";
  return axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": contentType ? contentType : "application/json",
      Accept: "application/json",
    },
    ...options,
  });
};

export default axiosInstance;
