import * as actionTypes from "./actionTypes";

const savedState = JSON.parse(localStorage.getItem("cartItems"));

const cartInitialState =
  savedState !== null
    ? { ...savedState }
    : {
        quantity: 0,
        items: [],
        subtotal: 0,
        isVisible: false,
      };

const cartReducer = (state = cartInitialState, action) => {
  switch (action.type) {
    case actionTypes.SYNC_CART:
      return {
        ...state,
        cartState: {
          ...state.cartState,
          quantity: action.items.length,
          items: action.items,
          subtotal: action.subtotal,
        },
      };
    case actionTypes.ADD_NEW_ITEM_TO_BASKET:
      return {
        ...state,
        cartState: {
          ...state.cartState,
          quantity: state.cartState.quantity + 1,
          items: state.cartState.items.concat(action.item),
          subtotal: action.subtotal,
        },
      };
    case actionTypes.UPDATE_ITEM_ON_BASKET:
      let item = state.cartState.items.filter((itm) => itm.id === action.id)[0];
      item = {
        ...item,
        purchasedUnits: item.purchasedUnits + action.update,
      };
      return {
        ...state,
        cartState: {
          ...state.cartState,
          items: state.cartState.items.map((itm) => (itm.id === action.id ? item : itm)),
          subtotal: action.subtotal,
        },
      };
    case actionTypes.REMOVE_ITEM:
      return {
        ...state,
        cartState: {
          ...state.cartState,
          items: state.cartState.items.filter((item) => item.id !== action.id),
          quantity: state.cartState.quantity - 1,
          subtotal: action.subtotal,
        },
      };
    case actionTypes.RESET_BASKET:
      return {
        ...state,
        cartState: {
          quantity: 0,
          items: [],
          subtotal: 0,
          isVisible: false,
        },
      };
    case actionTypes.SHOW_CART:
      return {
        ...state,
        cartState: {
          ...state.cartState,
          isVisible: true,
        },
      };
    case actionTypes.HIDE_CART:
      return {
        ...state,
        cartState: {
          ...state.cartState,
          isVisible: false,
        },
      };
    default:
      return state;
  }
};

export { cartReducer, cartInitialState };
