import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Product from "../../components/Product/Product";
import ProductSkeleton from "../../components/Product/ProductSkeleton";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { getProductCategories, getProducts } from "../../services/api/products";
import styles from "./Products.module.css";

function Products() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({ id: "", title: "" });
  const viewProductRef = useRef(null);

  const { width } = useWindowDimensions();

  const navigate = useNavigate();

  useEffect(() => {
    let abort = new AbortController();
    getProductCategories(abort.signal)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {});

    return () => {
      abort.abort();
    };
  }, []);
  useEffect(() => {
    let abort = new AbortController();
    setLoading(true);
    getProducts(selectedCategory.id, abort.signal)
      .then((res) => {
        setProducts(res.data.products);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    return () => {
      abort.abort();
    };
  }, [selectedCategory.id]);

  return (
    <section className={cx("section", styles.product)}>
      <div className="container" ref={viewProductRef}>
        <div className={styles.headerContainer}>
          <h2 className={cx("h2", "section-title", styles.sectionTitle)}>
            {selectedCategory.id === "" ? "All Products" : selectedCategory.title}
          </h2>
          <button
            className={styles.viewAllBtn}
            onClick={() =>
              navigate("/all-products/", {
                state: {
                  category: "All Products",
                },
              })
            }
          >
            View All <ion-icon name="chevron-forward-outline"></ion-icon>
          </button>
        </div>

        {loading ? (
          <div className={cx(styles.gridList, "grid-list")}>
            <ProductSkeleton />
            <ProductSkeleton />
            <ProductSkeleton />
            <ProductSkeleton />
            <ProductSkeleton />
          </div>
        ) : (
          <div className={cx(styles.gridList, "grid-list")}>
            {width < 480
              ? products.slice(0, 2).map((item, index) => <Product item={item} key={item.id} />)
              : width < 768
              ? products.slice(0, 4).map((item, index) => <Product item={item} key={item.id} />)
              : width < 992
              ? products.slice(0, 6).map((item, index) => <Product item={item} key={item.id} />)
              : products.map((item, index) => <Product item={item} key={item.id} />)}
          </div>
        )}
      </div>
    </section>
  );
}

export default Products;
