import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ImageCarousel } from "../../components/ImageCarousel";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { StoreContext } from "../../store/store";
import styles from "./Landing.module.css";

function Landing() {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const [state, dispatch] = useContext(StoreContext);
  const { homeState } = state;

  return (
    <main className={styles.container}>
      <article className={width >= 1200 ? "container" : null}>
        <section className={styles.gridContainer}>
          <div className={styles.sidebar}>
            <div className={styles.sidebarTop}>
              <h4 className={styles.sidebarTitle}>Categories</h4>
            </div>
            <ul className={styles.sidebarList}>
              {homeState.categories.map((item, index) => (
                <li
                  key={item.id}
                  className={styles.sidebarItem}
                  onClick={() => {
                    navigate(`all-products?category_id=${item.id}&title=${item.title}`);
                  }}
                >
                  <div className={styles.sideItem}>{item.title}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.card}>
            <div style={{ margin: "0 auto" }}>
              <div className={styles.sliderWrapper}>
                <ImageCarousel
                  data={homeState.sliders.map((item) => ({
                    id: item.id,
                    image: process.env.REACT_APP_SERVER_URL + item.image,
                    categories: item.product_category?.title,
                    category_id: item.category_id,
                  }))}
                />
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
}

export default Landing;
