import cx from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import styles from "./ImageCarousel.module.css";

function ImageCarousel({ data }) {
  const [selectedSlide, setSelectedSlide] = useState(0);
  const navigate = useNavigate();
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div>
        <div style={selectedSlide === i ? { color: "#FFFFFF" } : { color: "#DDDDDD" }}>
          <ion-icon name="ellipse"></ion-icon>
        </div>
      </div>
    ),
  };
  return (
    <div className={cx(styles.sliderContainer, "program-detail-slider")}>
      <Slider {...settings} className="program-slider" afterChange={setSelectedSlide}>
        {data.map((item, index) => {
          return (
            <div
              key={item.id}
              // onClick={() =>
              //   navigate(`all-products?category_id=${item.category_id}&title=${item.categories}`)
              // }
            >
              <div className={styles.imageContainer}>
                <img src={item.image} alt="details" className={styles.image} />
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default ImageCarousel;
