import React from "react";
import cx from "classnames";
import styles from "./NotFound.module.css";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <div className={cx("container", styles.container)}>
      <h1 className={styles.title}>404</h1>
      <h3 className={styles.subtitle}>Oops! Page Not Found.</h3>
      <p className={styles.details}>Something went wrong.</p>
      <button className={cx("btn btn-primary", styles.btn)} onClick={() => navigate("/")}>
        Go Home
      </button>
    </div>
  );
}

export default NotFound;
