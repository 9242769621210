import { Alert, AlertTitle, Box } from "@mui/material";
import React from "react";

function CheckoutCancel() {
  return (
    <Box m="90px auto" width="80%" height="50vh" className="container">
      <Alert severity="error">
        <AlertTitle>Cancelled</AlertTitle>
        Your order has been cancelled.
      </Alert>
    </Box>
  );
}

export default CheckoutCancel;
