import * as actionTypes from "./actionTypes";

// NOTE:
// user type 0 => retail
// user type 1 => wholesale

const userJSON = {
  isLoggedIn: false,
  id: null,
  isWholesale: false,
  username: null,
  contact: null,
  email: null,
  fetchData: false,
  favouritesCount: 0,
};

const userInitialState = userJSON;

const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case actionTypes.LOGGED_IN:
      let cartState = state.cartState;
      // console.log(localStorage.getItem("cartItems"));
      // console.log(userInitialState, "djadjajda");
      //first delete all the items that are not wholesale
      cartState = {
        ...cartState,

        // items: cartState.items.filter((itm) => itm.is_wholesale === 1),
      };

      // calculate the total amount from remaining items
      // and also update the quantity counter i.e. total number of remaining items

      return {
        ...state,
        userState: {
          isLoggedIn: true,
          id: action.payload.user_id,
          isWholesale: action.payload.is_wholesale,
          username: action.payload.name,
          contact: action.payload.mobile_number,
          email: action.payload.email,
          favouritesCount: action.payload.favourites_count,
          fetchData: true,
        },
        cartState: {
          ...cartState,
        },
      };
    case actionTypes.GET_USER_LOGGED_IN:
      // console.log(action.payload, "asakskskasksaksa");
      return {
        ...state,
        userState: {
          ...state.userState,
          isLoggedIn: true,
          id: action.payload.user_id,
          isWholesale: action.payload.is_wholesale,
          username: action.payload.name,
          contact: action.payload.mobile_number,
          email: action.payload.email,
          favouritesCount: action.payload.favourites_count,
          fetchData: true,
        },
      };
    case actionTypes.SET_USER:
      return {
        ...state,
        userState: {
          ...state.userState,
          isLoggedIn: true,
          id: action.payload.user_id,
          isWholesale: action.payload.is_wholesale,
          username: action.payload.name,
          contact: action.payload.mobile_number,
          email: action.payload.email,
          favouritesCount: action.payload.favourites_count,
          fetchData: true,
        },
      };

    case actionTypes.SET_FETCH_DATA:
      return {
        ...state,
        userState: {
          ...state.userState,
          fetchData: action.status,
        },
      };
    case actionTypes.LOGGED_OUT:
      return {
        ...state,
        userState: {
          ...userJSON,
        },
        // cartState: {
        //   ...state.cartState,
        //   quantity: 0,
        //   items: [],
        //   subtotal: 0,
        //   isVisible: false,
        // },
      };
    default:
      return state;
  }
};

export { userReducer, userInitialState };
