import * as actionTypes from "./actionTypes";

const homeInitialState = {
  categories: [],
  sliders: [],
  banners: [],
};

const homeReducer = (state = homeInitialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL:
      return {
        ...state,
        homeState: {
          sliders: action.sliders,
          banners: action.banners,
          categories: action.categories,
        },
      };
    default:
      return state;
  }
};

export { homeReducer, homeInitialState };
