import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton } from "@mui/material";
import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as cartActions from "../../store/cart/actionTypes";
import { StoreContext } from "../../store/store";
import styles from "./Product.module.css";

const Product = ({ item }) => {
  const [state, dispatch] = useContext(StoreContext);
  const navigate = useNavigate();
  const { userState, cartState } = state;
  const [itemCartState, setItemCartState] = useState(null);

  const btnStyle = {
    pointerEvents: "none",
    opacity: "0.65",
  };

  const handleIncrement = (item) => {
    const subtotal = cartState.subtotal + item.price1;
    dispatch({
      type: cartActions.UPDATE_ITEM_ON_BASKET,
      update: 1,
      id: item.id,
      subtotal: subtotal,
    });
  };

  const handleDecrement = (item) => {
    const subtotal = cartState.subtotal - item.price1;

    dispatch({
      type: cartActions.UPDATE_ITEM_ON_BASKET,
      update: -1,
      id: item.id,
      subtotal: subtotal,
    });
  };

  const productExists = (item) => {
    const isInCart = cartState.items.filter((itm) => itm.id === item.id);
    if (isInCart.length) return true;
    return false;
  };

  const addItem = (item) => {
    const subtotal = cartState.subtotal + item.price1;
    if (!productExists(item)) {
      item = {
        ...item,
        purchasedUnits: 1,
      };
      dispatch({ type: cartActions.ADD_NEW_ITEM_TO_BASKET, item: item, subtotal: subtotal });
    } else {
      dispatch({
        type: cartActions.UPDATE_ITEM_ON_BASKET,
        update: 1,
        id: item.id,
        subtotal: subtotal,
      });
    }
  };

  const removeItem = (item) => {
    const updatedUnits = cartState.items.filter((itm) => itm.id === item.id)[0].purchasedUnits;
    const subtotal = cartState.subtotal - item.price1 * updatedUnits;
    dispatch({
      type: cartActions.REMOVE_ITEM,
      id: item.id,
      subtotal: subtotal,
    });
  };

  useEffect(() => {
    const isInCart = cartState.items.find((itm) => itm.id === item.id);
    setItemCartState(isInCart);
  }, [cartState]);

  return (
    <div className={styles.productCard}>
      <figure className={styles.cardBanner}>
        <img
          src={process.env.REACT_APP_SERVER_URL + item.image}
          width="189"
          height="189"
          loading="lazy"
          alt="card"
          className={styles.image}
        />
        <div
          className={
            item.delivery_charge.title === "Dry Shipping" ? styles.badgeOne : styles.badgeTwo
          }
        >
          {item.delivery_charge.title}
        </div>
      </figure>
      <div className={styles.content}>
        <h3 className={cx("h3", styles.cardTitle)}>
          <div
            onClick={(event) => {
              {
                event.preventDefault();
                navigate(`/all-products/${item.id}`);
              }
            }}
          >
            {item.title}
          </div>
        </h3>
        <div className={styles.weightContainer}>
          <div className={styles.weight}>{item.weight / 1000} kg</div>
          {item.status === 1 ? (
            <div className={styles.stock}>In stock</div>
          ) : (
            <div className={styles.outOfStock}>Out of stock</div>
          )}
        </div>
        <div className={styles.flexbox}>
          <div className={styles.priceWrapper}>
            {/* {item.price2 && <del className={styles.del}>¥ {item.price2}</del>} */}¥{" "}
            <data className={styles.price}>{item.price1}</data>
          </div>

          {!itemCartState ? (
            <button
              className={cx("btn", "btnPrimary", styles.btn)}
              onClick={(event) => {
                event.stopPropagation();
                addItem(item);
              }}
              disabled={item.status !== 1}
              style={item.status !== 1 ? btnStyle : null}
            >
              {item.status !== 1 ? "Out of Stock" : "Add to Cart"}
            </button>
          ) : (
            <div className={styles.cartBtn}>
              <IconButton
                className={cx(styles.iconBtn, styles.removeIcon)}
                onClick={() => {
                  if (itemCartState.purchasedUnits > 1) {
                    handleDecrement(item);
                  } else {
                    removeItem(item);
                  }
                }}
              >
                <RemoveIcon fontSize="16px" />
              </IconButton>
              <p className={styles.cartNumber}>x{itemCartState.purchasedUnits}</p>
              <IconButton
                className={cx(styles.iconBtn, styles.addIcon)}
                onClick={() => {
                  handleIncrement(item);
                }}
              >
                <AddIcon fontSize="16px" />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Product;
