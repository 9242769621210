import React from "react";
import styles from "./Policies.module.css";
import cx from "classnames";

function Privacy() {
  return (
    <div className={cx(styles.container, "container")}>
      <h2 className={styles.title}>Privacy Policy</h2>
      <p className={styles.paragraph}>
        Welcome to the barahifoods.jp website (the ""Site"") operated by Barahi Foods japan tokyo.
        We respect your privacy and want to protect your personal information. To learn more, please
        read this Privacy Policy.
      </p>
      <p className={styles.paragraph}>
        This Privacy Policy explains how we collect, use and (under certain conditions) disclose
        your personal information. This Privacy Policy also explains the steps we have taken to
        secure your personal information. Finally, this Privacy Policy explains your options
        regarding the collection, use and disclosure of your personal information. By visiting the
        Site directly or through another site, you accept the practices described in this Policy.We
        will only collect information where it is necessary for us to do so and we will only collect
        information if it is relevant to our dealings with you.
      </p>
      <h4 className={styles.sectionTitle}>DATA</h4>
      <p className={styles.paragraph}>
        We collect, store and process your data for processing your purchase on the Site and any
        possible later claims, and to provide you with our services. We may collect personal
        information including, but not limited to, your title, name, gender, date of birth, email
        address, postal address, delivery address (if different), telephone number, mobile number,
        fax number, payment details, payment card details or bank account details Other uses of your
        Personal Information We may use your personal information for opinion and market research
        Any answers to surveys or opinion polls we may ask you to complete will not be forwarded on
        to third parties.
      </p>
      <h4 className={styles.sectionTitle}>SECURITY</h4>
      <p className={styles.paragraph}>
        We have in place appropriate technical and security measures to prevent unauthorized or
        unlawful access to or accidental loss of or destruction or damage to your information. When
        we collect data through the Site, we collect your personal details on a secure server.
      </p>
      <h4 className={styles.sectionTitle}>YOUR RIGHTS</h4>
      <p className={styles.paragraph}>
        If you are concerned about your data you have the right to request access to the personal
        data which we may hold or process about you. You have the right to require us to correct any
        inaccuracies in your data free of charge.
      </p>
    </div>
  );
}

export default Privacy;
