import cx from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { codCheckout } from "../../services/api/checkout";
import { getDeliveryCharge } from "../../services/api/products";
import { StoreContext } from "../../store/store";
import styles from "./Checkout.module.css";

function CashOnDelivery() {
  const location = useLocation();
  const [state, dispatch] = useContext(StoreContext);
  const { cartState } = state;

  const [cartTotal, setCartTotal] = useState({ subtotal: 0, deliveryCharge: 0 });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleCheckout = () => {
    setLoading(true);
    codCheckout(location?.state?.requestBody)
      .then((res) => {
        navigate(`/checkout/success?order_id=${res.data.unique_order_id}`);
        setLoading(false);
      })
      .catch((err) => {
        console.log("order failed");
        setLoading(false);
      });
  };

  useEffect(() => {
    let abort = new AbortController();
    getDeliveryCharge(cartState.items, abort.signal)
      .then((res) => {
        setCartTotal({
          deliveryCharge: res.data.delivery_charge,
          subtotal: res.data.products_charge,
        });
      })
      .catch((err) => {});

    return () => {
      abort.abort();
    };
  }, [cartState]);

  return (
    <div className={cx(styles.container, "container")}>
      <div className={styles.cardTitle}>Your Order</div>
      <div className={styles.gridContainer}>
        <div className={styles.content}>
          <div className={styles.userInfo}>
            <div className={styles.title}>Your Information</div>
            <div className={styles.details}>
              <div className={styles.detail}>{location.state?.requestBody?.user?.data?.name}</div>
              <div className={styles.detail}>{location.state?.paymentData?.email}</div>
            </div>
          </div>
          <div className={styles.contactInfo}>
            <div className={styles.title}>Contact Information</div>
            <div className={styles.details}>
              <div className={styles.detail}>{location.state?.paymentData?.phone_number}</div>
            </div>
          </div>
          <div className={styles.shippingInfo}>
            <div className={styles.title}>Shipping Address</div>
            <div className={styles.details}>
              <div className={styles.detail}>
                {location.state?.billingData?.street_address +
                  "-" +
                  location.state?.billingData?.house_number +
                  ", " +
                  location.state?.billingData?.street_address}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bill}>
          <div className={styles.title}>Products</div>
          {cartState?.items.map((item) => (
            <div key={item.id}>
              <div className={styles.billGrid}>
                <div>
                  {item.title} - {item.purchasedUnits} x {item.price1}
                </div>
                <div>¥ {item.purchasedUnits * item.price1}</div>
              </div>
            </div>
          ))}
          <div className={cx(styles.billGrid, styles.subtotal)}>
            <div className={styles.title}>Subtotal</div>
            <div className={styles.price}>¥ {cartTotal?.subtotal}</div>
            <div className={styles.title}>Delivery Charge</div>
            <div className={styles.price}>¥ {cartTotal?.deliveryCharge}</div>
          </div>

          <div className={cx(styles.billGrid, styles.total)}>
            <div className={styles.title}>Total Amount</div>
            <div className={styles.price}>¥ {cartTotal?.subtotal + cartTotal?.deliveryCharge}</div>
          </div>
          <button className={cx("btn btn-primary", styles.btn)} onClick={handleCheckout}>
            {loading ? "Place Order..." : "Place Order"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CashOnDelivery;
